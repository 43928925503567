<template>
  <div class="center" v-if="isLoad">
    <VSpinnerLoader />
  </div>
  <div v-else>
    <div class="block__subtitle mb-20">
      {{ getLot.id }}
    </div>
    <div class="between mb-40">
      <div class="lot-info__subtitle block__subtitle-small">
        {{ getLot.name }}
      </div>
    </div>

    <div class="grid grid--col-3 mb-20 p-30 bg--light-gray">
      <VTextBlock
        v-for="item in textBlockItems"
        :key="item.name"
        :subtitle="item.name"
        :description="paintDescriptionItem(item.value)"
      />
    </div>

    <!-- TODO DELETE скрытие поля 2.12.22  -->
    <div v-if="false" class="grid grid--col-2 p-30 mb-60 bg--yellow">
      <div class="grid grid--col-2 grid--lane">
        <div class="center">
          <VTextBlock
            subtitle="Начальная макс. цена закупки"
            :description="formatPrice(currentPrice)"
            font-size-description="38"
          />
        </div>
        <div class="center">
          <div class="grid">
            <VTextBlock
              subtitle="Шаг снижения начальной цены"
              :description="getLot.min_step"
            />
            <VTextBlock
              subtitle="Ед. измерения шага снижения"
              :description="getLot.is_procent ? 'Проценты' : 'Конкретная сумма'"
            />
          </div>
        </div>
      </div>
      <div />
    </div>

    <div class="between">
      <div class="block__subtitle mb-40">
        ПОЗИЦИИ
      </div>
    </div>

    <div v-if="getLot.position?.length">
      <VPositionCardList
        class="mb-60"
        :position-card-list="getLot.position"
      />
    </div>

    <div v-if="permissionReadDocuments" class="block__subtitle-small mb-40">
      Сопроводительная документация
    </div>
    <VFileList
      v-if="permissionReadDocuments"
      is-download
      :files="files"
    />
  </div>
</template>

<script>
import VFileList from '@/components/list/FileList/VFileList'
import VPositionCardList from '@/components/list/PositionCardList/VPositionCardList'
import VTextBlock from '@/components/textblock/VTextBlock'
import useToggleDisplay from '@/use/Base/useToggleDisplay'
import { accordionList } from '@/mocks/accordion'

import router from '@/router'
import { useStore } from 'vuex'
import { computed, inject, onBeforeMount, reactive, ref } from 'vue'
import { formatPrice } from '@/utils/utils'
import VSpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'
import { Alert } from '@/extension/Alert/Alert'
import { BIDDING_CONTROLLER } from '@/utils/consts'
import { usePermissionReadOfferInfo } from '@/use/usePermission'

export default {
  setup () {
    const store = useStore()
    const isLoad = ref(true)
    const biddingProcedureController = inject(BIDDING_CONTROLLER)
    const currentPrice = ref(0)
    const changeDescriptionList = new Map([
      ['currency', (lot) => {
        return lot.currency?.name
      }],
      ['note', (lot) => {
        return lot.note || 'Организатор не указал'
      }],
      ['delivery_address', (lot) => {
        return lot.delivery_address?.name
      }],
      ['payment_term', (lot) => {
        return lot.payment_term?.name
      }],
      ['deliveryCondition', (lot) => {
        return lot.deliveryCondition?.name
      }],
      ['procedureType', (lot) => {
        return lot.procedureType?.name
      }],
      ['nds_counter', (lot) => {
        return lot.nds_counter + '%'
      }],
      ['relation_products_name', (lot) => {
        return lot?.relationToProducts?.name || 'Не указан'
      }]
    ])
    const files = reactive([])
    const textBlockItems = [
      {
        name: 'Дата начала',
        value: 'started_at'
      },
      {
        name: 'Дата окончания',
        value: 'duration'
      },
      {
        name: 'Примечание организатора',
        value: 'note'
      },
      {
        name: 'Тип лота',
        value: 'procedureType'
      },
      {
        name: 'Условия поставки',
        value: 'deliveryCondition'
      },
      {
        name: 'Адрес поставки',
        value: 'address'
      },
      {
        name: 'Частота поставки',
        value: 'delivery_frequency'
      },
      {
        name: 'Условия оплаты',
        value: 'payment'
      },
      {
        name: 'Валюта закупки',
        value: 'currency'
      },
      {
        name: 'Ставка НДС',
        value: 'nds_counter'
      },
      {
        name: 'Отношение к продукции',
        value: 'relation_products_name'
      }
    ]

    const paintDescriptionItem = (key) => {
      if (changeDescriptionList.has(key)) {
        return changeDescriptionList.get(key)(getLot.value)
      }
      return getLot.value[key]
    }

    const goEdit = () => {
      router.push({ name: 'bidder-lot-create' })
    }

    const changeLotPosition = (positions, lot) => {
      lot.position = reactive([])
      for (let i = 0; i < positions.length; i++) {
        const currentPosition = positions[i].answers.position
        lot.position.push(currentPosition)
      }
    }

    function transformLot(lot) {
      lot.address = ''
      lot.payment = lot.paymentTerm?.name
      lot.delivery_address.forEach((addr, idx) => {
        lot.address += `${idx ? ', ' : ''}${addr.name}`
      })
    }

    onBeforeMount(async () => {
      try {
        isLoad.value = true
        const id = router.currentRoute.value.params.id
        const userId = router.currentRoute.value.params.userId
        const sort = router.currentRoute.value.query.sortId
        const isFake = router.currentRoute.value.query.isFake
        const { data } = await biddingProcedureController.getOfferForEditView(id, userId, sort, isFake)
        const lot = data.lot_offer.fields
        const lotFiles = data.lot_offer.file
        const positions = data.position_offer
        transformLot(lot)
        console.log('positions', positions)
        changeLotPosition(positions, lot)

        if (lot.procedure_type_id === 3 || lot.procedure_type_id === 4) {
          await router.push({ name: 'bidder' })
        }

        store.commit('lot/setLot', lot)

        if (lotFiles?.length) {
          files.push(...lotFiles)
        }
      } catch (e) {
        await Alert.show('error', e)
      } finally {
        isLoad.value = false
      }
    })

    const getLot = computed(() => {
      return store.getters['lot/getLot']
    })

    const permissionReadDocuments = usePermissionReadOfferInfo()

    return {
      currentPrice,
      files,
      isLoad,
      getLot,
      ...useToggleDisplay(),
      textBlockItems,
      paintDescriptionItem,
      goEdit,
      accordionList,
      formatPrice,
      permissionReadDocuments
    }
  },
  components: {
    VSpinnerLoader,
    VPositionCardList,
    VFileList,
    VTextBlock
  }
}
</script>
