import { ref } from 'vue'

export default function () {
  const display = ref('blocks')

  const setDisplay = value => {
    display.value = value
  }

  return {
    display,
    setDisplay
  }
}
