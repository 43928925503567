import { computed } from 'vue'
import store from '@/store'
import router from '@/router'

export function usePermissionReadOfferInfo () {
  return computed(() => {
    if (store.getters['auth/isAdmin'] || store.getters['auth/isOrganizer'] || store.getters['auth/isSecurity']) {
      return true
    }
    return store.getters['auth/isBidder']
      && store.getters['auth/currentUserId'] === +router.currentRoute.value.params.userId
  })
}
