<template>
  <ul class="position-card-list grid grid--col-3 grid--gap-20">
    <li
      class="position-card-list__item"
      v-for="(item, i) in positionCardList"
      :key="item.id"
    >
      <VPositionCard
        :item="item"
        :count="i + 1"
      />
    </li>
  </ul>
</template>

<script>
import VPositionCard from '@/components/list/PositionCardList/VPositionCard'

export default {
  props: {
    positionCardList: {
      type: Array,
      required: true
    }
  },
  components: {
    VPositionCard
  }
}
</script>

<style scoped>

</style>
