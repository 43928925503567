export const accordionList = [
  {
    id: '1',
    price: 20000,
    title: 'Посыпка шарики разноцветные микс №1 (яркие)',
    list: [
      { title: 'Заголовок 1', text: 'Какой то текст 1' },
      { title: 'Заголовок 2', text: 'Какой то текст 2' },
      { title: 'Заголовок 3', text: 'Какой то текст 3' },
      { title: 'Заголовок 4', text: 'Какой то текст 4' },
      { title: 'Заголовок 5', text: 'Какой то текст 5' },
      { title: 'Заголовок 6', text: 'Какой то текст 6' }
    ]
  },
  {
    id: '2',
    price: 21000,
    title: 'Посыпка шарики разноцветные микс №2 (яркие)',
    list: [
      { title: 'Заголовок 1', text: 'Какой то текст 1' },
      { title: 'Заголовок 2', text: 'Какой то текст 2' },
      { title: 'Заголовок 3', text: 'Какой то текст 3' },
      { title: 'Заголовок 4', text: 'Какой то текст 4' },
      { title: 'Заголовок 5', text: 'Какой то текст 5' },
      { title: 'Заголовок 6', text: 'Какой то текст 6' }
    ]
  }
]
